import images from '../../images';
import { Modal, Table, Button, Icon } from 'semantic-ui-react';

const ShowResultUpdateModal = ({ open, results, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    size="large" // Cambiado a 'large' para mayor espacio
    style={{
      borderRadius: '20px',
      margin: 'auto',
      top: '5%',
      bottom: '5%',
      left: '5%',
      right: '5%',
      maxWidth: '80%', // Ajuste dinámico del ancho
      overflowY: 'auto', // Permitir scroll si es necesario
    }}
  >
    <Modal.Header style={{ textAlign: 'center' }}>Resultados de Actualización</Modal.Header>
    <Modal.Content>
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6} textAlign="center">Titulo</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">Mercado</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">Estado</Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="center">Razon</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {results.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell
                style={{
                  textAlign:"center",
                   verticalAlign:"middle",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.title}
              </Table.Cell>
              <Table.Cell textAlign="center" verticalAlign="middle">
                <img
                  src={item.marketplace === 'mercado_libre' ? images.meliIcon : images.tnIcon}
                  alt="icon"
                  style={{ width: '30px', height: '30px' }}
                />
              </Table.Cell>
              <Table.Cell textAlign="center" verticalAlign="middle">
                {item.status === 'OK' ? (
                  <Icon name="check circle" color="green" size="large" />
                ) : (
                  <Icon name="times circle" color="red" size="large" title={extractErrorMessages(item.error)} />
                )}
              </Table.Cell>
              <Table.Cell
                style={{
                  textAlign:"center",
                  verticalAlign:"middle",
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {extractErrorMessages(item.error)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose} color="blue">
        Cerrar
      </Button>
    </Modal.Actions>
  </Modal>
);

const extractErrorMessages = (error) => {
  try {
    const parsedError = JSON.parse(error.match(/{.*}/)[0]);
    const mainMessage = parsedError.message;
    const causeMessage = parsedError.cause?.[0]?.message || 'No further details';
    return `${mainMessage} - ${causeMessage}`;
  } catch (e) {
    console.error('Failed to parse error:', e);
    return error; // Si falla, devolvemos el mensaje completo
  }
};
export default ShowResultUpdateModal;