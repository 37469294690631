import React, { useState } from 'react';
import { Table, Icon, Button, Pagination, Breadcrumb } from 'semantic-ui-react';

const ItemsTable = ({
                        column,
                        direction,
                        handleSort,
                        handleSelectAll,
                        selectAll,
                        TableContentComponent,
                        filteredItems = [], // Default value for filteredItems
                        selectedItems,
                        setSelectedItems,
                        handleEditClick,
                        handleViewClick,
                    }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        const savedItemsPerPage = localStorage.getItem('itemsPerPage');
        return savedItemsPerPage ? parseInt(savedItemsPerPage, 10) : 10;
    });

    const totalPages = Math.ceil((filteredItems?.length || 0) / itemsPerPage); // Safely access length

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        localStorage.setItem('itemsPerPage', value);  // Save to localStorage
        setCurrentPage(1); // Reset to first page on items per page change
    };

    const itemsPerPageOptions = [
        { key: 10, value: 10, text: '10' },
        { key: 20, value: 20, text: '20' },
        { key: 50, value: 50, text: '50' },
        { key: 100, value: 100, text: '100' },
    ];

    return (
        <>

<Table celled striped selectable>
    <Table.Header>
        <Table.Row>
            <Table.HeaderCell style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px', width: '50px' }}>
                <Button basic icon={selectAll ? 'check square' : 'square outline'} onClick={handleSelectAll} />
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'title' ? direction : null}
                onClick={() => handleSort('title')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '300px',
                    minWidth: '300px',
                    maxWidth: '300px',  // Añadir estos dos estilos
                }}
            >
                Título
                {column === 'title' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'variationName' ? direction : null}
                onClick={() => handleSort('variationName')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '120px',
                    minWidth: '120px',
                    maxWidth: '120px',  // Añadir estos dos estilos
                }}
            >
                Variation
                {column === 'variationName' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'sku' ? direction : null}
                onClick={() => handleSort('sku')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '120px',
                    minWidth: '120px',
                    maxWidth: '120px',  // Añadir estos dos estilos
                }}
            >
                SKU
                {column === 'sku' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'availableQuantity' ? direction : null}
                onClick={() => handleSort('availableQuantity')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '80px',
                    minWidth: '80px',
                    maxWidth: '80px',  // Añadir estos dos estilos
                }}
            >
                Stock
                {column === 'availableQuantity' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'price' ? direction : null}
                onClick={() => handleSort('price')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '120px',
                    minWidth: '120px',
                    maxWidth: '120px',  // Añadir estos dos estilos
                }}
            >
                Precio
                {column === 'price' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'status' ? direction : null}
                onClick={() => handleSort('status')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '90px',
                    minWidth: '90px',
                    maxWidth: '90px',  // Añadir estos dos estilos
                }}
            >
                Estado
                {column === 'status' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'seller.nickname' ? direction : null}
                onClick={() => handleSort('seller.nickname')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '180px',
                    minWidth: '180px',
                    maxWidth: '180px',  // Añadir estos dos estilos
                }}
            >
                Vendedor
                {column === 'seller.nickname' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell
                sorted={column === 'marketplace' ? direction : null}
                onClick={() => handleSort('marketplace')}
                style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '50px',
                    minWidth: '50px',
                    maxWidth: '50px',  // Añadir estos dos estilos
                }}
            >
                
                {column === 'marketplace' && <Icon name={direction === 'ascending' ? 'arrow up' : 'arrow down'} />}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '50px' }}></Table.HeaderCell>
        </Table.Row>
    </Table.Header>

    <TableContentComponent
        args={{
            filteredItems: currentItems,
            selectedItems,
            setSelectedItems,
            handleEditClick,
            handleViewClick
        }}
    />
    <Table.Footer>
        <Table.Row>
            <Table.HeaderCell colSpan="10">
                <div className="ui small label">
                    <i className="file alternate outline icon"></i>
                    Páginas
                </div>
                <Breadcrumb>
                    {itemsPerPageOptions.map((option, index) => (
                        <React.Fragment key={option.key}>
                            <Breadcrumb.Section
                                active={itemsPerPage === option.value}
                                onClick={() => handleItemsPerPageChange(option.value)}
                                style={{ cursor: 'pointer', color: 'purple' }}
                            >
                                {option.text}
                            </Breadcrumb.Section>
                            {index < itemsPerPageOptions.length - 1 && <Breadcrumb.Divider>/</Breadcrumb.Divider>}
                        </React.Fragment>
                    ))}
                </Breadcrumb>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
</Table>
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
    <Pagination
        activePage={currentPage}
        onPageChange={handlePaginationChange}
        totalPages={totalPages}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        color="purple"
    />
</div>



        </>
    );
};

export default ItemsTable;
