import React, { useEffect, useState } from 'react';
import {Form, Icon, Grid, Container, Segment, Label, Dimmer, Loader} from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { stockyApi } from '../misc/StockyApi';
import { useAuth } from '../context/AuthContext';
import images from '../../images';
import AddTrackingScript from '../tracking/AddTrackingScript';

function NewAccount() {
    const [accountType, setAccountType] = useState('mercado_libre');
    const navigate = useNavigate();
    const location = useLocation();
    const { getUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const user = getUser();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const accountType = params.get('accountType');

        if (code && accountType) {
            syncAccount(code, accountType);
        }
    }, [location]);

    const syncAccount = async (code, accountType) => {
        try {
            setIsLoading(true);
            const response = await stockyApi.syncAccount(code, accountType, user);
            if (response.status === 200) {
                console.log('Account synchronized successfully');
                alert("Sincronización realizada correctamente");
                navigate('/');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to synchronize the account', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageClick = async (type) => {
        setAccountType(type);
        try {
            setIsLoading(true);
            const response = await stockyApi.getNewAccountUrl(type, user);
            if (response.status === 200) {
                const redirectUrl = response.data.url;
                window.location.href = redirectUrl;
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Failed to get the redirect URL', error);
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <AddTrackingScript />

            {isLoading && (
                <Dimmer active inverted>
                    <Loader inverted size='huge'>Loading</Loader>
                </Dimmer>
            )}

            <Segment color='purple'>
                <Label attached='top' color='purple'>Sinconizar</Label>
                <Grid stackable columns={3} style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Grid.Row columns={3}>
                        <Grid.Column style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
                            <div
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    cursor: 'pointer',
                                    borderRadius: '15px',
                                    boxShadow: '0 8px 15px rgba(138, 43, 226, 0.3)',
                                    transition: 'transform 0.3s ease, box-shadow 0.2s ease',
                                }}
                                onClick={() => handleImageClick('mercado_libre')}
                                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <img
                                    src={images.mercadoLibre}
                                    alt="Mercado Libre"
                                    style={{
                                        width: '70%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '20px',
                                    }}
                                />
                            </div>
                        </Grid.Column>

                        <Grid.Column style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
                            <div
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    cursor: 'pointer',
                                    borderRadius: '15px',
                                    boxShadow: '0 8px 15px rgba(138, 43, 226, 0.3)',
                                    transition: 'transform 0.3s ease, box-shadow 0.2s ease',
                                }}
                                onClick={() => handleImageClick('tienda_nube')}
                                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <img
                                    src={images.tiendaNube}
                                    alt="Tienda Nube"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '20px',
                                    }}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Segment>
        </Container>
    );
}

export default NewAccount;
